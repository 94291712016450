<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
      <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>复制管理</el-breadcrumb-item>
  <el-breadcrumb-item>帮助中心</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">
  <div class="">
    宝贝复制：目前只支持阿里<br>
    <el-image
          style=" height: 100%"
          :src="require('@/assets/images/fuzhi/1.jpg')"
          fit="fit"></el-image>
  </div>
</el-card>
<!-- 内容 结束 -->

    </el-main>
    </el-container>
</el-container>
</template>

<script>
import { onMounted } from 'vue'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

export default {
  name: 'fuzhiHelp',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    onMounted(() => {
    })
    return {
      navActive: '2-3'
    }
  }
}
</script>
